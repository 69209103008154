<template>
    <nav class="navbar navbar-light navbar-expand-sm">
        <div class="container">
            <a class="navbar-brand" href="//www.amigosdobem.org" target="_blank">
                <img src="../../assets/img/logos/logo-color.svg" width="30" height="30" alt="">
            </a>

            <div class="navbar-title" :class="{'d-none d-lg-block': !!logoPicture}">
                {{ _store_getAuthSettingsProp('name') }}
            </div>

            <div class="navbar-partner" v-if="logoPicture">
                <img :src="logoPicture" width="30" height="30" alt="">
            </div>

            <slot></slot>
        </div>
    </nav>
</template>

<style scoped>
nav.navbar {
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12);
}

.navbar .navbar-brand {
    padding: 2px 0;
    margin: 0;
}

nav.navbar .navbar-brand img,
nav.navbar .navbar-partner img {
    width: auto;
    height: 100%;
    max-height: 50px;
}

nav.navbar .navbar-title {
    font-weight: 500;
    color: #666;
}

@media (min-width: 800px) {
    nav.navbar .navbar-brand img,
    nav.navbar .navbar-partner img {
        max-height: 60px;
    }
}

@media (min-width: 768px) {
    nav.navbar .navbar-title {
        font-size: 1.3rem;
    }
}

@media (min-width: 992px) {
    nav.navbar .navbar-title {
        font-size: 1.6rem;
    }
}

@media (min-width: 1400px) {
    nav.navbar .navbar-brand img,
    nav.navbar .navbar-partner img {
        max-height: 70px;
    }

    nav.navbar .navbar-title {
        font-size: 1.8rem;
    }

    nav.navbar .navbar-menu button {
        font-size: 2rem;
    }
}
</style>

<script>
    import {mapGetters} from "vuex";
    import CommonMixin from "../../mixins/CommonMixin";

    export default {
        mixins: [CommonMixin],
        data() {
            return {
                showMenu: false,
                logoPicture: null,
                listener: null
            }
        },
        methods: {
            getLogoPicture() {
                let logoPicture = this._store_getAuthSettingsProp('logo');

                if (!logoPicture) return;

                this._common_getUserAsset(logoPicture)
                    .getDownloadURL()
                    .then(url => this.logoPicture = url);
            }
        },
        computed: {
            ...mapGetters([
                '_store_getAuthSettingsProp'
            ])
        },
        created() {
            this.getLogoPicture();
        }
    }
</script>

