<template>
    <main>
        <navbar-hotsite>
            <a v-if="content.donate_url"
               :href="content.donate_url"
               class="btn btn-custom">{{ donateBtnLabel }}</a>
        </navbar-hotsite>

        <template v-if="dateCampaign.isReady">

            <campaign-ended-hotsite v-if="dateCampaign.ended"
                                    :aggregate="campaign.aggregate"
                                    :init="campaign.init"
                                    :showSidebar="showSidebar"/>

            <template v-else>
                <notifications classes="notify-new-donation"
                               group="new-donation"
                               position="top left"
                               :width="notificationWidth"/>

                <div class="container columns mb-3 mt-3">
                    <div class="row">
                        <div class="col-lg-8 mb-3 col-mobile">
                            <div class="card">
                                <you-tube-player v-if="hasYouTubeSource"/>
                                <banner-rotator v-else class="rotate-backgrounds"/>
                                <div class="card-body">
                                    <div v-html="content.body"></div>
                                </div>
                            </div>
                        </div>

                        <div v-if="showSidebar" class="col-lg-4 col-mobile">
                            <div class="card mb-3">
                                <div class="card-body">
                                    <hotsite-sidebar :aggregate="campaign.aggregate"
                                                     :value-formatter="valueFormatter"
                                                     :init="campaign.init">
                                        <a v-if="content.donate_url"
                                           :href="content.donate_url"
                                           class="btn btn-block btn-custom">{{ donateBtnLabel }}</a>
                                    </hotsite-sidebar>
                                </div>
                            </div>

                            <div v-if="showLatestDonations" class="card">
                                <div class="card-body">
                                    <h3 class="mb-3">Últimas doações</h3>
                                    <latest-donations v-if="campaign.aggregate && campaign.aggregate.latest"
                                                      :donations="campaign.aggregate.latest"
                                                      :donor-name="'first_only'"
                                                      :value-formatter="valueFormatter"
                                                      :display-amount="showLatestDonationsAmount"
                                                      :limit="5"/>
                                    <p v-else-if="!campaign.init" class="flex-grow-1 text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center">
                                        <em>Não há doações</em>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>

        <footer-hotsite/>
    </main>
</template>

<style>
@media (max-width: 768px) {
    p {
        font-size: .9rem;
    }

    h3 {
        font-size: 18px;
    }

    .card-body {
        padding: .8rem;
    }

    .col-mobile {
        padding-left: 8px;
        padding-right: 8px;
    }
}
</style>

<style scoped>
.columns {
    margin-top: 1%;
}

.rotate-backgrounds {
    width: 100%;
    border-radius: .25rem .25rem 0 0;
    margin: 0;
    padding: 0;
}
</style>

<script>
import {mapGetters} from "vuex";
import CampaignMixin from "../../mixins/CampaignMixin";
import NavbarHotsite from "../../components/navbars/NavbarHotsite";
import Formatter from "../../helpers/Formatter";
import BannerRotator from "../../components/banners/BannerRotator";
import HotsiteSidebar from "../../components/sidebars/HotsiteSidebar";
import LatestDonations from "../../components/lists/LatestDonations";
import FooterHotsite from "../../components/footers/FooterHotsite";
import CampaignEndedHotsite from "../../components/warnings/EndedCampaignHotsite";
import DataHelper from "../../helpers/DataHelper";
import YouTubePlayer from "@/components/players/YouTubePlayer";
import AmountConverterHelper from "@/helpers/AmountConverterHelper";

export default {
    mixins: [CampaignMixin],
    components: {
        YouTubePlayer,
        BannerRotator,
        CampaignEndedHotsite,
        FooterHotsite,
        LatestDonations,
        HotsiteSidebar,
        NavbarHotsite
    },
    data() {
        return {
            amountConverter: new AmountConverterHelper()
        }
    },
    methods: {
        _hook_onNewDonationCreated(donation) {
            const name = DataHelper.getFirstStringPart(donation.donor.name);

            this.$notify({
                duration: 2000,
                group: 'new-donation',
                title: 'Doação Recebida',
                text: `${name}`
            });
        },
        valueFormatter(value) {
            if (this.amountConverter.hasConverter()) {
                return this.amountConverter.convert(value);
            }

            return Formatter.formatCurrency(value, true, 0);
        }
    },
    computed: {
        ...mapGetters([
            '_store_getAuthSettingsProp'
        ]),
        content() {
            return this._store_getAuthSettingsProp('content');
        },
        showSidebar() {
            return !this._store_getAuthSettingsProp('hide_sidebar');
        },
        showLatestDonations() {
            return !this._store_getAuthSettingsProp('hide_latest_donations');
        },
        showLatestDonationsAmount() {
            return !!this._store_getAuthSettingsProp('show_latest_donations_amount');
        },
        donateBtnLabel() {
            return this._store_getAuthSettingsProp('donate_btn_label') || 'DOAR';
        },
        hasYouTubeSource() {
            return this._store_getAuthSettingsProp('youtube_src');
        },
        notificationWidth() {
            return (window.innerWidth < 400 ? 300 : 400) + 'px';
        }
    }
}
</script>

