<template>
    <iframe v-if="source"
            :src="source"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
            frameborder="0"></iframe>
</template>

<style scoped>
iframe {
    width: 100%;
    height: 200px;
}

@media (min-width: 400px) {
    iframe {
        height: 220px;
    }
}

@media (min-width: 600px) {
    iframe {
        height: 300px;
    }
}

@media (min-width: 768px) {
    iframe {
        height: 350px;
    }
}

@media (min-width: 1200px) {
    iframe {
        height: 410px;
    }
}
</style>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            source: null
        }
    },
    methods: {
        getSource() {
            this.source = this._store_getAuthSettingsProp('youtube_src');
        },
    },
    computed: {
        ...mapGetters([
            '_store_getAuthSettingsProp'
        ])
    },
    created() {
        this.getSource();
    }
}
</script>
