<template>
    <div :key="forceKey">
        <div v-for="donation in topDonations" :key="donation.key" class="donation d-flex align-content-around">

            <div class="icon">
                <font-awesome-icon icon="user-circle"/>
            </div>

            <div class="donor flex-grow-1">
                <p>{{ getDonorName(donation.donor.name) }}</p>
                <p class="timestamp text-muted">
                    {{ donation.timestamp | formatFromNow }}
                </p>
            </div>

            <div class="badges d-flex align-items-center">
                <strong v-if="displayAmount" class="badge badge-secondary quantity">
                    {{ valueFormatter(donation.amount) }}
                </strong>

                <strong v-if="getMetadata(donation)" class="badge badge-info metadata">
                    {{ getMetadata(donation) }}
                </strong>
            </div>
        </div>
    </div>
</template>

<style scoped>
div.donation {
    color: #333;
    font-size: 1rem;
}

div.donation p {
    margin-bottom: 1px;
}

div.donation .donor {
    font-size: .85rem;
}

div.donation .icon {
    line-height: 1;
    margin-right: 10px;
    font-size: 2.2rem;
    color: #aaa;
}

div.donation .timestamp {
    margin-right: 10px;
}

div.donation strong {
    margin-left: 10px;
}

div.donation .quantity {
    margin-left: 10px;
}

div.donation .metadata {
    margin-left: 2px;
}

@media (min-width: 1400px) {
    div.donation {
        font-size: 1.1rem;
        margin-bottom: .5rem;
    }

    div.donation .donor {
        font-size: .9rem;
    }

    div.donation .icon {
        font-size: 2.4rem;
    }
}
</style>

<script>
import DataHelper from "../../helpers/DataHelper";
import "../../filters/default";

export default {
    props: {
        donations: {
            type: Array,
            required: true
        },
        limit: {
            type: Number,
            default: 5
        },
        metadata: {
            type: String,
            default: ''
        },
        donorName: {
            type: String,
            default: 'first_last'
        },
        displayAmount: {
            type: Boolean,
            default: true
        },
        valueFormatter: {
            type: Function,
            default: (v) => {
                return v;
            }
        },
        metadataFormatter: {
            type: Function,
            default: (v) => {
                return v;
            }
        }
    },
    data() {
        return {
            forceKey: 0,
            forceSeconds: 60
        }
    },
    methods: {

        getDonorName(name) {
            if (this.donorName === 'anonymus') return 'Anônimo';
            return this.donorName === 'first_only'
                ? DataHelper.getFirstStringPart(name)
                : name;
        },
        setTimeInterval() {
            window.setInterval(() => {
                this.forceKey = (new Date()).getMinutes();
            }, this.forceSeconds * 1000);
        },
        getMetadata(donation) {
            return (this.metadata &&
                donation.metadata &&
                donation.metadata[this.metadata])
                ? this.metadataFormatter(donation.metadata[this.metadata])
                : '';
        }
    },
    computed: {
        topDonations() {
            return DataHelper.getArrayTop(this.donations, this.limit);
        }
    },
    created() {
        this.setTimeInterval();
    }
}
</script>


