<template>
    <div class="container columns mb-3">
        <div class="row">
            <div class="col-lg-8 mb-3">
                <div class="card">
                    <img v-if="pictureUrl" class="img-fluid" :src="pictureUrl" alt="">
                    <div class="card-body">
                        <h1 class="card-title text-success text-center uppercase font-weight-bolder">{{ title }}</h1>
                        <p class="text-center">{{ message }}</p>
                    </div>
                </div>
            </div>
            <div v-if="showSidebar" class="col-lg-4 mb-3">
                <div class="card mb-3">
                    <div class="card-body">
                        <hotsite-sidebar :aggregate="aggregate" :init="init"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateCampaignMixin from "./DateCampaignMixin";
import HotsiteSidebar from "../sidebars/HotsiteSidebar";

export default {
    mixins: [DateCampaignMixin],
    components: {
        HotsiteSidebar
    },
    props: {
        aggregate: {
            required: true
        },
        init: {
            type: Boolean,
            required: true
        },
        showSidebar: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dateField: 'end_campaign',
            title: 'Obrigado!',
            message: 'Campanha encerrada!',
            pictureUrl: null
        }
    }
}
</script>

