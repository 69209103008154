<template>
    <footer>
        <div class="container">
            <div class="columns">
                <div class="logo">
                    <a href="https://www.amigosdobem.org" target="_blank">
                        <img src="@/assets/img/logos/logo-white.svg" alt="">
                    </a>
                </div>
                <div class="contact-and-seals">
                    <div class="contact">
                        <h3>
                            AMIGOS DO BEM INSTITUIÇÃO NACIONAL CONTRA A FOME E A MISÉRIA
                        </h3>
                        <p class="cnpj">
                            CNPJ 05.108.918/0001-72
                        </p>
                        <p class="address hidden-xs">
                            {{ address }}
                        </p>
                        <p class="email">
                            {{ email }}
                        </p>
                        <p class="phone">
                            {{ phone }}
                        </p>
                    </div>
                    <div class="seals">
                        <div class="column">
                            <a class="seal" href="https://www.amigosdobem.org/transparencia/" target="_blank">
                                <img src="@/assets/img/logos/logo-ey.svg" height="100" alt="">
                            </a>
                            <a class="seal" href="https://www.premiomelhores.org/conheca-as-melhores-ongs-de-2022/" target="_blank">
                                <img src="@/assets/img/logos/logo-melhores-ongs.svg" height="100" alt="">
                            </a>
                            <a class="seal" href="https://www.institutodoar.org/certificado-amigos-do-bem/" target="_blank">
                                <img src="@/assets/img/logos/logo-padrao-doar-2.svg" height="100" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
footer {
    background: #333;
    color: #fff;
    min-height: 100px;
    padding: 20px 0;
}

footer .columns {
    display: flex;
    flex-direction: row;
}

footer .columns .contact-and-seals {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    gap: 30px;
}

footer .columns .logo {
    margin-right: 15px;
    max-width: 86px;
}

footer .columns .logo img {
    min-width: 79px;
    width: 100%;
}

footer .columns .contact {
    display: flex;
    flex-direction: column;
}

footer .columns .contact h3 {
    color: #fff;
    font-size: 15px;
    margin: 0;
    padding-bottom: 7px;
}

footer .columns .contact p {
    font-size: 80%;
    margin: 0;
}

footer .columns .seals {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    gap: 30px;
}

footer .columns .seals .column {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

footer .columns .seal {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 5%;
}

footer .columns .seal img {
    width: auto;
    max-height: 75px;
}

@media (max-width: 991px) {


    footer .columns .seal img {
        max-height: 70px;
    }
}

@media (max-width: 991px) {
    footer .columns .logo {
        display: none;
    }
}

@media (max-width: 768px) {
    footer .columns .contact-and-seals {
        flex-direction: column;
    }

    footer .columns .seals {
        flex-direction: column;
    }
}
</style>

<script>
export default {
    props: {
        address: {
            type: String,
            default: 'Rua Dr. Gabriel de Resende, 122. São Paulo/SP'
        },
        email: {
            type: String,
            default: 'relacionamento@amigosdobem.org'
        },
        phone: {
            type: String,
            default: '(11) 3019-0100'
        }
    }
};
</script>
