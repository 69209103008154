import {store} from '@/stores/authStore';
import DataHelper from './DataHelper';

export default class AmountConverterHelper {
    constructor() {
        this.settings = store.getters._store_getAuthSettingsProp('amount_converter');
    }

    hasConverter() {
        return typeof this.settings === 'object' &&
            !Array.isArray(this.settings) &&
            this.settings !== null
    }

    convert(amount, label = true, precision = 0) {
        const converted = amount / this.settings.rate;
        const rounded = this.settings.round
            ? DataHelper.roundNumber(converted, precision)
            : DataHelper.floorNumber(converted)

        return label
            ? `${rounded} ${this.settings.label}`
            : rounded
    }
}
