<template>
    <div class="counters">

        <p v-if="!init" class="flex-grow-1 text-center text-muted">
            <font-awesome-icon icon="circle-notch" spin size="3x"/>
        </p>
        <template v-else>
            <div class="counter d-flex" :class="{'text-muted': expired}">
                <p class="icon">
                    <font-awesome-icon icon="hand-holding-usd" />
                </p>
                <p class="flex-grow-1">
                    <b>{{ quantity }}</b> {{ quantity === 0 || quantity > 1 ? 'doações' : 'doação' }}
                </p>
            </div>
            <div class="counter d-flex" v-if="daysRemaining !== null">
                <p class="icon">
                    <font-awesome-icon icon="clock" />
                </p>
                <p class="flex-grow-1">
                    <template v-if="daysRemaining >= 0">
                        <b>{{ daysRemaining }}</b> dias restantes
                    </template>
                    <template v-else>
                        <b class="text-muted">ENCERRADA</b>
                    </template>
                </p>
            </div>
            <div class="counter" :class="{'text-muted': expired}">
                <p>
                    <b>{{ valueFormatter(amount) }}</b>
                    <span v-if="target" class="small-90"> / {{ valueFormatter(target) }}</span>
                </p>
                <div v-if="target" class="progress mb-3">
                    <div class="progress-bar" :class="{'bg-secondary': expired}" role="progressbar"
                         :style="{ 'width' : progress+'%'}">
                        <template v-if="progress > 10">{{ progress }}%</template>
                    </div>
                </div>
            </div>
        </template>
        <div class="counter">
            <slot></slot>
            <template v-if="daysRemaining !== null">
                <p v-if="daysRemaining >= 0" class="text-muted small-85">
                    Esta campanha se encerra no dia {{ deadline | formatDate }}
                </p>
                <p v-else class="text-muted small-85">
                    Esta campanha encerrou-se no dia {{ deadline | formatDate }}
                </p>
            </template>
        </div>

    </div>
</template>

<style scoped>
b {
    font-size: 1.6rem;
}

p {
    margin: .5rem 0;
}

p.icon {
    margin: 0;
    font-size: 2rem;
    color: #aaa;
}

.counter {
    text-align: center;
    margin: 10px 0;
    font-size: 1.3rem;
}

.counter:not(:first-child) {
    padding-top: 10px;
}

.counter:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
}

.progress {
    height: 25px;
    font-weight: bold;
}

.small-90 {
    font-size: .9rem;
}

.small-85 {
    font-size: .85rem;
}
</style>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import Formatter from "../../helpers/Formatter";

export default {
    props: {
        aggregate: {
            required: true
        },
        init: {
            type: Boolean,
            required: true
        },
        valueFormatter: {
            type: Function,
            default: (v) => {
                return Formatter.formatCurrency(v, true, 0);
            }
        }
    },
    computed: {
        ...mapGetters([
            '_store_getAuthSettingsProp'
        ]),
        amount() {
            return this.aggregate ? this.aggregate.sum_amount : 0;
        },
        quantity() {
            return this.aggregate ? this.aggregate.count_items : 0;
        },
        target() {
            return this._store_getAuthSettingsProp('target');
        },
        progress() {
            const target = this.target ? this.target : 1;
            const percentage = this.amount / target * 100;
            return percentage < 1
                ? Math.ceil(percentage)
                : Math.floor(percentage);
        },
        deadline() {
            const endCampaign = this._store_getAuthSettingsProp('end_campaign');
            return endCampaign.date || null;
        },
        expired() {
            return this.daysRemaining !== null && this.daysRemaining < 1;
        },
        daysRemaining() {
            if (!this.deadline) return null;
            const now = moment();
            const deadline = moment(this.deadline);
            let diff = deadline.diff(now, 'days');

            if (now.isBefore(deadline)) {
                diff++;
            }

            return diff;
        }
    }
}
</script>

