import { render, staticRenderFns } from "./HotsiteSidebar.vue?vue&type=template&id=28e9ecfc&scoped=true"
import script from "./HotsiteSidebar.vue?vue&type=script&lang=js"
export * from "./HotsiteSidebar.vue?vue&type=script&lang=js"
import style0 from "./HotsiteSidebar.vue?vue&type=style&index=0&id=28e9ecfc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e9ecfc",
  null
  
)

export default component.exports